body {
    margin: 0;
}
.App {
    background-color: #282c34;
    position: relative;
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: vmin;
    color: white;
}
