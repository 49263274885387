.nutrientsTable {
    border-collapse: collapse;
    margin: 1em 1em 1em 1em;
    font-size: 1em;
    font-family: sans-serif;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.35);
    justify-content: center;
    /* max-width: 48%; */
}


.nutrientsTable thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.nutrientsTable th,
.nutrientsTable td {
    padding: 12px 15px;
}

.nutrientsTable tbody tr {
    border-bottom: 1px solid #dddddd;
}

.nutrientsTable tbody tr:nth-of-type(even) {
    background-color: #393c44;
}

.nutrientsTable tbody tr:nth-of-type(odd) {
    background-color: #282c34;
}

.nutrientsTable tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.nutrientsTable tbody tr.active-search-option {
   background-color: #501839;
}

.nutrientsTable tbody tr.deficient {
    background-color: #FFAD02;
}

.nutrientsTable tbody tr.adequate {
    background-color: #501839;
}

.nutrientsTable tbody tr.unknown {
    background-color: #000000;
}

.nutrientsTable tbody tr.excessive {
    background-color: #C70039;
}

.closeButton {
    background-color: #008060;
}

.foodForm input {
    font-size: 2em;
    background-color: inherit;
    color: white;
}

.searchResult {
    font-size: 2em;
    width: inherit;
}

.userInformation input {
    background-color: inherit;
    size: 3em;
    width: 4em;
    color: white;
 }

.foodQuantity input {
    background-color: inherit;
    size: 3em;
    width: 4em;
    color: white;
}

.formatSelector {
    background-color: #393c44;
    size: 3em;
    color: white;
}

.sexSelector {
    background-color: #282c34;
    color: white;
}

.listSelector {
    background-color: #282c34;
    color: white;
}
